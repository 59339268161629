import React from 'react';

export function Alert({ children, className, ...props }) {
  return (
    <div className={`p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg ${className}`} {...props}>
      {children}
    </div>
  );
}

export function AlertDescription({ children, ...props }) {
  return <p {...props}>{children}</p>;
}
