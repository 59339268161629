import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { Label } from "../components/ui/label";
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "../components/ui/popover";
import { Calendar } from "../components/ui/calendar";
import { CalendarIcon } from "lucide-react";
import { format } from "date-fns";
import { cn } from "../lib/utils";
import { Alert, AlertDescription } from "../components/ui/alert";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../components/ui/alert-dialog";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "../components/ui/select";
import { useWindowSize } from "../hooks/use-window-size";

const AddEmployee = () => {
  const [name, setName] = useState('');
  const [paymentDate, setPaymentDate] = useState();
  const [position, setPosition] = useState('');
  const [monthlySalary, setMonthlySalary] = useState('');
  const [alertInfo, setAlertInfo] = useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const { width } = useWindowSize();
  const isMobile = width < 640;

  useEffect(() => {
    let timer;
    if (alertInfo) {
      timer = setTimeout(() => {
        setAlertInfo(null);
      }, 3000);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [alertInfo]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowConfirmDialog(true);
  };

  const confirmAddEmployee = async () => {
    try {
      await addDoc(collection(db, 'employees'), {
        name,
        payment_date: paymentDate ? format(paymentDate, 'yyyy-MM-dd') : null,
        position,
        monthlySalary: parseFloat(monthlySalary)
      });
      setAlertInfo({ type: 'success', message: 'Employee added successfully!' });
      setName('');
      setPaymentDate(undefined);
      setPosition('');
      setMonthlySalary('');
    } catch (error) {
      console.error('Error adding employee:', error);
      setAlertInfo({ type: 'error', message: 'Failed to add employee. Please try again.' });
    }
    setShowConfirmDialog(false);
  };

  const handleDateSelect = (selectedDate) => {
    setPaymentDate(selectedDate);
    setIsCalendarOpen(false);
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Add Employee</CardTitle>
      </CardHeader>
      <CardContent>
        {alertInfo && (
          <Alert variant={alertInfo.type === 'success' ? 'default' : 'destructive'} className="mb-4">
            <AlertDescription>
              <strong>{alertInfo.type === 'success' ? 'Success: ' : 'Error: '}</strong>
              {alertInfo.message}
            </AlertDescription>
          </Alert>
        )}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="name">Employee Name</Label>
            <Input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="paymentDate">Payment Date</Label>
            <Popover open={isCalendarOpen} onOpenChange={setIsCalendarOpen}>
              <PopoverTrigger asChild>
                <Button
                  variant={"outline"}
                  className={cn(
                    "w-full justify-start text-left font-normal",
                    !paymentDate && "text-muted-foreground"
                  )}
                >
                  <CalendarIcon className="mr-2 h-4 w-4" />
                  {paymentDate ? format(paymentDate, "PPP") : <span>Pick a date</span>}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0" align="start">
                <Calendar
                  mode="single"
                  selected={paymentDate}
                  onSelect={handleDateSelect}
                  initialFocus
                />
              </PopoverContent>
            </Popover>
          </div>
          <div className="space-y-2">
            <Label htmlFor="position">Position</Label>
            <Select value={position} onValueChange={setPosition}>
              <SelectTrigger id="position">
                <SelectValue placeholder="Select a position" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="Manager">Manager</SelectItem>
                <SelectItem value="Receptionist">Receptionist</SelectItem>
                <SelectItem value="Housekeeping">Housekeeping</SelectItem>
                <SelectItem value="Day Shift">Day Shift</SelectItem>
                <SelectItem value="Night Shift">Night Shift</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="space-y-2">
            <Label htmlFor="monthlySalary">Monthly Salary</Label>
            <Input
              type="number"
              id="monthlySalary"
              value={monthlySalary}
              onChange={(e) => setMonthlySalary(e.target.value)}
              required
              className="focus:ring-0 focus:ring-offset-0 focus:outline-none focus:border-input"
            />
          </div>
          <Button type="submit" className="w-full">Add Employee</Button>
        </form>

        <AlertDialog open={showConfirmDialog} onOpenChange={setShowConfirmDialog}>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Confirm Add Employee</AlertDialogTitle>
            </AlertDialogHeader>
            <AlertDialogDescription>
              Are you sure you want to add the following employee:
            </AlertDialogDescription>
            <ul className="mt-2">
              <li><strong>Name:</strong> {name}</li>
              <li><strong>Payment Date:</strong> {paymentDate ? format(paymentDate, "PPP") : 'Not specified'}</li>
              <li><strong>Position:</strong> {position}</li>
              <li><strong>Monthly Salary:</strong> {monthlySalary}</li>
            </ul>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction onClick={confirmAddEmployee}>Confirm</AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </CardContent>
    </Card>
  );
};

export default AddEmployee;
