import React from 'react';
import { BrowserRouter as Router, Route, Routes, useOutletContext } from 'react-router-dom';
import Layout from './components/Layout';
import Login from './pages/Login';
import Home from './pages/Home';
// Import other pages as needed
import AddEmployee from './pages/AddEmployee';
import RemoveEmployee from './pages/RemoveEmployee';
import AttendanceDashboard from './pages/AttendanceDashboard';
import UpdateAttendance from './pages/UpdateAttendance';
import AttendanceSummary from './pages/AttendanceSummary';
import EmployeeDetails from './pages/EmployeeDetails';
import PaymentSummary from './pages/PaymentSummary'; // Add this line
import { ThemeProvider } from './components/theme-provider';

const EmployeeDetailsWrapper = () => {
  const { isAdmin } = useOutletContext();
  return <EmployeeDetails isAdmin={isAdmin} />;
};

function App() {
  return (
    <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/add-employee" element={<AddEmployee />} />
            <Route path="/remove-employee" element={<RemoveEmployee />} />
            <Route path="/attendance-dashboard" element={<AttendanceDashboard />} />
            <Route path="/update-attendance" element={<UpdateAttendance />} />
            <Route path="/attendance-summary" element={<AttendanceSummary />} />
            <Route path="/employee-details" element={<EmployeeDetailsWrapper />} />
            <Route path="/payment-summary" element={<PaymentSummary />} /> // Add this line
          </Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;