import React, { useState, useEffect } from 'react';
import { format, differenceInDays, startOfMonth, endOfMonth, eachDayOfInterval, parseISO, startOfDay, endOfDay } from 'date-fns';
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { Badge } from "../components/ui/badge";
import { Button } from "../components/ui/button";
import { Calendar } from "../components/ui/calendar";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../components/ui/table";
import { Input } from "../components/ui/input";
import { Label } from "../components/ui/label";
import { Popover, PopoverContent, PopoverTrigger } from "../components/ui/popover";
import { collection, query, where, getDocs, addDoc, deleteDoc, doc, updateDoc, onSnapshot, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { cn } from "../lib/utils";
import { CalendarIcon } from "lucide-react";
import { useWindowSize } from "../hooks/use-window-size";

const getBadgeColor = (position) => {
  switch (position) {
    case 'Manager': return 'bg-blue-500 hover:bg-blue-600';
    case 'Receptionist': return 'bg-green-500 hover:bg-green-600';
    case 'Housekeeping': return 'bg-purple-500 hover:bg-purple-600';
    case 'Day Shift': return 'bg-yellow-500 hover:bg-yellow-600';
    case 'Night Shift': return 'bg-red-500 hover:bg-red-600';
    default: return 'bg-gray-500 hover:bg-gray-600';
  }
};

const EmployeeDetails = ({ isAdmin }) => {
  console.log("Is Admin:", isAdmin);  // Add this line
  
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [dateRange, setDateRange] = useState({ from: startOfMonth(new Date()), to: endOfMonth(new Date()) });
  const [tempDateRange, setTempDateRange] = useState({ from: undefined, to: undefined });
  const [attendance, setAttendance] = useState({});
  const [payments, setPayments] = useState([]);
  const [newPayment, setNewPayment] = useState({ date: new Date(), amount: '', type: 'advance' });
  const [isDateRangeCalendarOpen, setIsDateRangeCalendarOpen] = useState(false);
  const [isNewPaymentCalendarOpen, setIsNewPaymentCalendarOpen] = useState(false);
  const { width } = useWindowSize();
  const isMobile = width < 640;
  const [unsubscribePayments, setUnsubscribePayments] = useState(null);
  const [unsubscribeAttendance, setUnsubscribeAttendance] = useState(null);

  useEffect(() => {
    const fetchEmployees = async () => {
      const employeesCollection = collection(db, 'employees');
      const employeesSnapshot = await getDocs(employeesCollection);
      const employeesList = employeesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setEmployees(employeesList);
    };

    fetchEmployees();
  }, []);

  useEffect(() => {
    if (selectedEmployee && dateRange.from && dateRange.to) {
      const fetchAttendanceAndPayments = async () => {
        const attendanceCollection = collection(db, 'attendance');
        const attendanceQuery = query(
          attendanceCollection,
          where('employee_id', '==', selectedEmployee.id),
          where('date', '>=', format(dateRange.from, 'yyyy-MM-dd')),
          where('date', '<=', format(dateRange.to, 'yyyy-MM-dd'))
        );

        // Set up real-time listener for attendance
        const unsubscribeAtt = onSnapshot(attendanceQuery, (snapshot) => {
          const attendanceData = {};
          snapshot.forEach(doc => {
            const data = doc.data();
            attendanceData[data.date] = data.status;
          });
          setAttendance(attendanceData);
        });

        // Store the unsubscribe function for attendance
        setUnsubscribeAttendance(() => unsubscribeAtt);

        const paymentsCollection = collection(db, 'payments');
        const paymentsQuery = query(
          paymentsCollection,
          where('employee_id', '==', selectedEmployee.id),
          where('date', '>=', format(dateRange.from, 'yyyy-MM-dd')),
          where('date', '<=', format(dateRange.to, 'yyyy-MM-dd'))
        );

        // Set up real-time listener for payments
        const unsubscribePay = onSnapshot(paymentsQuery, (snapshot) => {
          const paymentsList = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setPayments(paymentsList);
        });

        // Store the unsubscribe function for payments
        setUnsubscribePayments(() => unsubscribePay);
      };

      fetchAttendanceAndPayments();

      // Clean up the listeners when the component unmounts or when dependencies change
      return () => {
        if (unsubscribePayments) {
          unsubscribePayments();
        }
        if (unsubscribeAttendance) {
          unsubscribeAttendance();
        }
      };
    }
  }, [selectedEmployee, dateRange]);

  const handleDateRangeSelect = (selectedRange) => {
    setTempDateRange(selectedRange);
    if (selectedRange?.from && selectedRange?.to) {
      setDateRange({
        from: startOfDay(selectedRange.from),
        to: endOfDay(selectedRange.to)
      });
      setIsDateRangeCalendarOpen(false);
    }
  };

  const handleNewPaymentDateSelect = (selectedDate) => {
    setNewPayment({ ...newPayment, date: selectedDate });
    setIsNewPaymentCalendarOpen(false);
  };

  const calculateSalary = () => {
    if (!selectedEmployee || !dateRange.from || !dateRange.to) return null;

    const daysInPayPeriod = differenceInDays(dateRange.to, dateRange.from) + 1;
    const dailyRate = selectedEmployee.monthlySalary / daysInPayPeriod;
    
    const presentDays = Object.values(attendance).filter(status => status === 'present').length;
    const absentDays = Object.values(attendance).filter(status => status === 'absent').length;
    const graceAbsences = 2;
    
    // Total paid days is present days plus all absent days
    const paidDays = presentDays + absentDays;

    const advancePayments = payments
      .filter(payment => payment.type === 'advance')
      .reduce((total, payment) => total + (parseFloat(payment.amount) || 0), 0);
    
    const monthlyPayments = payments
      .filter(payment => payment.type === 'monthly')
      .reduce((total, payment) => total + (parseFloat(payment.amount) || 0), 0);
    
    const totalPayments = advancePayments + monthlyPayments;

    const unpaidAbsentDays = Math.max(absentDays - graceAbsences, 0);
    const deductionForAbsences = unpaidAbsentDays * dailyRate;
    const payPeriodSalary = Math.max((dailyRate * paidDays) - totalPayments - deductionForAbsences, 0);

    return {
      fixedMonthlySalary: selectedEmployee.monthlySalary,
      payPeriodSalary,
      advancePayments,
      monthlyPayments,
      totalPayments,
      presentDays,
      absentDays,
      graceAbsences,
      paidDays,
      daysInPayPeriod,
      dailyRate,
      unpaidAbsentDays,
      deductionForAbsences
    };
  };

  const handleAddPayment = async () => {
    if (!selectedEmployee || !newPayment.amount) return;

    try {
      await addDoc(collection(db, 'payments'), {
        employee_id: selectedEmployee.id,
        date: format(newPayment.date, 'yyyy-MM-dd'),
        amount: parseFloat(newPayment.amount),
        type: newPayment.type
      });

      // Reset the new payment form
      setNewPayment({ date: new Date(), amount: '', type: 'advance' });
    } catch (error) {
      console.error('Error adding payment:', error);
    }
  };

  const handleRemovePayment = async (paymentId) => {
    try {
      await deleteDoc(doc(db, 'payments', paymentId));
    } catch (error) {
      console.error('Error removing payment:', error);
    }
  };

  const handleEditPayment = async (paymentId, newAmount) => {
    try {
      await updateDoc(doc(db, 'payments', paymentId), { amount: parseFloat(newAmount) });
    } catch (error) {
      console.error('Error editing payment:', error);
    }
  };

  // Add a function to update attendance if needed
  const updateAttendance = async (date, status) => {
    if (!selectedEmployee) return;

    const attendanceRef = doc(db, 'attendance', `${selectedEmployee.id}_${date}`);
    try {
      await setDoc(attendanceRef, {
        employee_id: selectedEmployee.id,
        date: date,
        status: status
      }, { merge: true });
    } catch (error) {
      console.error('Error updating attendance:', error);
    }
  };

  const handleEmployeeSelect = (value) => {
    setSelectedEmployee(employees.find(emp => emp.id === value));
  };

  return (
    <div className="space-y-6">
      <Card className="mb-4">
        <CardHeader>
          <CardTitle>Employee Details</CardTitle>
        </CardHeader>
        <CardContent>
          <Select onValueChange={handleEmployeeSelect}>
            <SelectTrigger>
              <SelectValue placeholder="Select an employee" />
            </SelectTrigger>
            <SelectContent>
              {employees.map((emp) => (
                <SelectItem key={emp.id} value={emp.id}>{emp.name}</SelectItem>
              ))}
            </SelectContent>
          </Select>
        </CardContent>
      </Card>

      {selectedEmployee && (
        <div className="select-none">
          <Card className="mb-4">
            <CardHeader>
              <CardTitle>Employee Information</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-2">
                <div className="flex items-center">
                  <span className="font-semibold">Name:</span>
                  <span className="ml-2">{selectedEmployee.name}</span>
                  <Badge 
                    className={`ml-2 w-fit text-white ${getBadgeColor(selectedEmployee.position)}`}
                  >
                    {selectedEmployee.position}
                  </Badge>
                </div>
                <p><span className="font-semibold">Monthly Salary:</span> ₹{selectedEmployee.monthlySalary}</p>
                <p>
                  <span className="font-semibold">Payment Date:</span> {' '}
                  {selectedEmployee.payment_date ? format(new Date(selectedEmployee.payment_date), "PPP") : 'Not specified'}
                </p>
              </div>
            </CardContent>
          </Card>

          <Card className="mb-4">
            <CardHeader>
              <CardTitle>Pay Period</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="flex space-x-4">
                <Popover 
                  open={isDateRangeCalendarOpen} 
                  onOpenChange={(open) => {
                    setIsDateRangeCalendarOpen(open);
                    if (open) {
                      setTempDateRange({ from: undefined, to: undefined });
                    }
                  }}
                >
                  <PopoverTrigger asChild>
                    <Button
                      variant={"outline"}
                      className={cn(
                        "w-[240px] justify-start text-left font-normal",
                        !dateRange && "text-muted-foreground"
                      )}
                    >
                      <CalendarIcon className="mr-2 h-4 w-4" />
                      {dateRange.from ? (
                        dateRange.to ? (
                          <>
                            {format(dateRange.from, "LLL dd, y")} -{" "}
                            {format(dateRange.to, "LLL dd, y")}
                          </>
                        ) : (
                          format(dateRange.from, "LLL dd, y")
                        )
                      ) : (
                        <span>Pick a date range</span>
                      )}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      initialFocus
                      mode="range"
                      defaultMonth={dateRange.from}
                      selected={tempDateRange}
                      onSelect={handleDateRangeSelect}
                      numberOfMonths={isMobile ? 1 : 2}
                    />
                  </PopoverContent>
                </Popover>
              </div>
            </CardContent>
          </Card>

          <Card className="mb-4">
            <CardHeader>
              <CardTitle>Attendance Summary</CardTitle>
            </CardHeader>
            <CardContent>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Date</TableHead>
                    <TableHead>Status</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {eachDayOfInterval({ start: dateRange.from, end: dateRange.to }).map((date) => {
                    const formattedDate = format(date, 'yyyy-MM-dd');
                    const status = attendance[formattedDate] || 'N/A';
                    return (
                      <TableRow key={formattedDate}>
                        <TableCell>{format(date, 'dd MMM yyyy')}</TableCell>
                        <TableCell>
                          {isAdmin ? (
                            <Select
                              value={status}
                              onValueChange={(newStatus) => updateAttendance(formattedDate, newStatus)}
                            >
                              <SelectTrigger>
                                <SelectValue>
                                  <Badge variant={status === 'present' ? 'green' : status === 'absent' ? 'destructive' : 'secondary'}>
                                    {status}
                                  </Badge>
                                </SelectValue>
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="present">Present</SelectItem>
                                <SelectItem value="absent">Absent</SelectItem>
                                <SelectItem value="N/A">N/A</SelectItem>
                              </SelectContent>
                            </Select>
                          ) : (
                            <Badge variant={status === 'present' ? 'green' : status === 'absent' ? 'destructive' : 'secondary'}>
                              {status === 'present' ? 'Present' : status === 'absent' ? 'Absent' : status}
                            </Badge>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </CardContent>
          </Card>

          <Card className="mb-4">
            <CardHeader>
              <CardTitle>Payments</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                {isAdmin && (
                  <div className={`${isMobile ? 'space-y-2' : 'flex space-x-2'}`}>
                    <Popover open={isNewPaymentCalendarOpen} onOpenChange={setIsNewPaymentCalendarOpen}>
                      <PopoverTrigger asChild>
                        <Button
                          variant={"outline"}
                          className={cn(
                            "w-full justify-start text-left font-normal",
                            !newPayment.date && "text-muted-foreground"
                          )}
                        >
                          {newPayment.date ? format(newPayment.date, "PPP") : <span>Pick a date</span>}
                          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0" align="start">
                        <Calendar
                          mode="single"
                          selected={newPayment.date}
                          onSelect={handleNewPaymentDateSelect}
                          initialFocus
                        />
                      </PopoverContent>
                    </Popover>
                    {isMobile ? (
                      <>
                        <div className="flex space-x-2">
                          <Input
                            type="number"
                            placeholder="Amount"
                            value={newPayment.amount}
                            onChange={(e) => setNewPayment({ ...newPayment, amount: e.target.value })}
                            className="flex-grow"
                          />
                          <Select 
                            value={newPayment.type} 
                            onValueChange={(value) => setNewPayment({ ...newPayment, type: value })}
                          >
                            <SelectTrigger className="w-32">
                              <SelectValue placeholder="Type" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value="advance">Advance</SelectItem>
                              <SelectItem value="monthly">Monthly Payment</SelectItem>
                            </SelectContent>
                          </Select>
                        </div>
                        <Button onClick={handleAddPayment} className="w-full">
                          Add Payment
                        </Button>
                      </>
                    ) : (
                      <>
                        <Input
                          type="number"
                          placeholder="Amount"
                          value={newPayment.amount}
                          onChange={(e) => setNewPayment({ ...newPayment, amount: e.target.value })}
                          className="desktop-no-spinner"
                        />
                        <Select 
                          value={newPayment.type} 
                          onValueChange={(value) => setNewPayment({ ...newPayment, type: value })}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Type" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="advance">Advance</SelectItem>
                            <SelectItem value="monthly">Monthly Payment</SelectItem>
                          </SelectContent>
                        </Select>
                        <Button onClick={handleAddPayment}>Add Payment</Button>
                      </>
                    )}
                  </div>
                )}
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Date</TableHead>
                      <TableHead>Amount</TableHead>
                      <TableHead>Type</TableHead>
                      {isAdmin && <TableHead>Actions</TableHead>}
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {payments.map((payment) => (
                      <TableRow key={payment.id}>
                        <TableCell>{format(new Date(payment.date), 'dd MMM yyyy')}</TableCell>
                        <TableCell>
                          {isAdmin ? (
                            <Input
                              type="number"
                              value={payment.amount}
                              onChange={(e) => handleEditPayment(payment.id, e.target.value)}
                            />
                          ) : (
                            `₹${payment.amount}`
                          )}
                        </TableCell>
                        <TableCell>
                          <Badge variant={payment.type === 'advance' ? 'secondary' : 'green'}>
                            {payment.type === 'advance' ? 'Advance Payment' : 'Monthly Payment'}
                          </Badge>
                        </TableCell>
                        {isAdmin && (
                          <TableCell>
                            <Button variant="destructive" onClick={() => handleRemovePayment(payment.id)}>Remove</Button>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </CardContent>
          </Card>

          <Card className="mb-4">
            <CardHeader>
              <CardTitle>Salary Calculation</CardTitle>
            </CardHeader>
            <CardContent>
              {calculateSalary() && (
                <div>
                  <p>Fixed Monthly Salary: ₹{calculateSalary().fixedMonthlySalary.toFixed(2)}</p>
                  <p>Daily Rate: ₹{calculateSalary().dailyRate.toFixed(2)}</p>
                  <p>Days in Pay Period: {calculateSalary().daysInPayPeriod}</p>
                  <p>Present Days: {calculateSalary().presentDays}</p>
                  <div className="flex items-center">
                    <span>Absent Days: {calculateSalary().absentDays}</span>
                    {calculateSalary().graceAbsences > 0 && (
                      <Badge variant="green" className="ml-2">
                        {calculateSalary().graceAbsences} Free Absences
                      </Badge>
                    )}
                  </div>
                  <p>Total Working Days: {calculateSalary().presentDays + calculateSalary().absentDays}</p>
                  <p>Absent Deduction: ₹{calculateSalary().deductionForAbsences.toFixed(2)}</p>
                  <p>Advance Payments: ₹{calculateSalary().advancePayments.toFixed(2)}</p>
                  <p>Monthly Payments: ₹{calculateSalary().monthlyPayments.toFixed(2)}</p>
                  <p>Total Payments: ₹{calculateSalary().totalPayments.toFixed(2)}</p>
                  <p className="font-bold mt-4">Pay Period Salary: ₹{calculateSalary().payPeriodSalary.toFixed(2)}</p>
                </div>
              )}
            </CardContent>
          </Card>
        </div>
      )}
    </div>
  );
};

export default EmployeeDetails;