import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, doc, deleteDoc } from 'firebase/firestore';
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { Label } from "../components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { Button } from "../components/ui/button";
import { Alert, AlertDescription } from "../components/ui/alert";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../components/ui/alert-dialog";

const RemoveEmployee = () => {
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [alertInfo, setAlertInfo] = useState(null);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const employeesCollection = collection(db, 'employees');
        const employeesSnapshot = await getDocs(employeesCollection);
        const employeesList = employeesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setEmployees(employeesList);
      } catch (error) {
        console.error('Error fetching employees:', error);
        setAlertInfo({ type: 'error', message: 'Error fetching employees. Please try again.' });
      }
    };

    fetchEmployees();
  }, []);

  useEffect(() => {
    let timer;
    if (alertInfo) {
      timer = setTimeout(() => {
        setAlertInfo(null);
      }, 3000);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [alertInfo]);

  const handleRemove = async () => {
    if (!selectedEmployee) return;

    try {
      await deleteDoc(doc(db, 'employees', selectedEmployee));
      setEmployees(employees.filter(emp => emp.id !== selectedEmployee));
      setSelectedEmployee('');
      setIsDialogOpen(false);
      setAlertInfo({ type: 'success', message: 'Employee removed successfully' });
    } catch (error) {
      console.error('Error removing employee:', error);
      setAlertInfo({ type: 'error', message: 'Error removing employee. Please try again.' });
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Remove Employee</CardTitle>
      </CardHeader>
      <CardContent>
        {alertInfo && (
          <Alert variant={alertInfo.type === 'success' ? 'default' : 'destructive'} className="mb-4">
            <AlertDescription>
              <strong>{alertInfo.type === 'success' ? 'Success: ' : 'Error: '}</strong>
              {alertInfo.message}
            </AlertDescription>
          </Alert>
        )}
        <form onSubmit={(e) => e.preventDefault()} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="employee">Select Employee</Label>
            <Select value={selectedEmployee} onValueChange={setSelectedEmployee}>
              <SelectTrigger id="employee">
                <SelectValue placeholder="Select an employee" />
              </SelectTrigger>
              <SelectContent>
                {employees.map((emp) => (
                  <SelectItem key={emp.id} value={emp.id}>{emp.name}</SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          {selectedEmployee && (
            <AlertDialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
              <AlertDialogTrigger asChild>
                <Button variant="destructive" className="w-full">
                  Remove Employee
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                  <AlertDialogDescription>
                    This action cannot be undone. This will permanently delete the employee
                    from our database.
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                  <AlertDialogAction onClick={handleRemove}>Confirm Remove</AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          )}
        </form>
      </CardContent>
    </Card>
  );
};

export default RemoveEmployee;
