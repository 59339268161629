import React, { useState, useEffect } from 'react';
import { format, differenceInDays, startOfMonth, endOfMonth, startOfDay, endOfDay, isSameMonth } from 'date-fns';
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { Label } from "../components/ui/label";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../components/ui/table";
import { Badge } from "../components/ui/badge";
import { Button } from "../components/ui/button";
import { Calendar } from "../components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "../components/ui/popover";
import { cn } from "../lib/utils";
import { CalendarIcon, ChevronLeft, ChevronRight } from "lucide-react";
import { useWindowSize } from "../hooks/use-window-size";
import { collection, query, where, getDocs, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';

const MonthCalendar = ({ value, onChange }) => {
  const [year, setYear] = useState(value.getFullYear());

  const months = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const handleYearChange = (increment) => {
    setYear(prevYear => prevYear + increment);
  };

  const handleMonthSelect = (month) => {
    onChange(new Date(year, month, 1));
  };

  return (
    <div className="p-3">
      <div className="flex justify-between items-center mb-4">
        <Button
          variant="outline"
          size="icon"
          onClick={() => handleYearChange(-1)}
        >
          <ChevronLeft className="h-4 w-4" />
        </Button>
        <span className="text-lg font-semibold">{year}</span>
        <Button
          variant="outline"
          size="icon"
          onClick={() => handleYearChange(1)}
        >
          <ChevronRight className="h-4 w-4" />
        </Button>
      </div>
      <div className="grid grid-cols-3 gap-2">
        {months.map((month, index) => {
          const date = new Date(year, index, 1);
          const isSelected = isSameMonth(date, value);
          return (
            <Button
              key={month}
              onClick={() => handleMonthSelect(index)}
              variant={isSelected ? "default" : "outline"}
              className={cn(
                "h-10",
                isSelected && "bg-primary text-primary-foreground hover:bg-primary/90",
                !isSelected && "hover:bg-accent hover:text-accent-foreground"
              )}
            >
              {month}
            </Button>
          );
        })}
      </div>
    </div>
  );
};

const PaymentSummary = () => {
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [payments, setPayments] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [filterType, setFilterType] = useState('month');
  const [dateRange, setDateRange] = useState({ from: undefined, to: undefined });
  const [periodDays, setPeriodDays] = useState(0);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isSelectingFilterType, setIsSelectingFilterType] = useState(false);
  const { width } = useWindowSize();
  const isMobile = width < 640;

  useEffect(() => {
    const fetchEmployees = async () => {
      const employeesCollection = collection(db, 'employees');
      const employeesSnapshot = await getDocs(employeesCollection);
      const employeesList = employeesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      const sortedEmployees = employeesList.sort((a, b) => a.name.localeCompare(b.name));
      setEmployees(sortedEmployees);
    };

    fetchEmployees();
  }, []);

  useEffect(() => {
    if (selectedEmployee && (filterType === 'month' || (filterType === 'custom' && dateRange.from && dateRange.to))) {
      let start, end;
      if (filterType === 'month') {
        start = startOfMonth(selectedMonth);
        end = endOfMonth(selectedMonth);
      } else {
        start = dateRange.from;
        end = dateRange.to;
      }

      const formattedStart = format(start, 'yyyy-MM-dd');
      const formattedEnd = format(end, 'yyyy-MM-dd');

      const paymentsCollection = collection(db, 'payments');
      const paymentsQuery = query(
        paymentsCollection,
        where('employee_id', '==', selectedEmployee.id),
        where('date', '>=', formattedStart),
        where('date', '<=', formattedEnd)
      );

      const unsubscribe = onSnapshot(paymentsQuery, (snapshot) => {
        const paymentsList = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setPayments(paymentsList);
      });

      // Calculate the number of days in the selected period
      const days = differenceInDays(end, start) + 1;
      setPeriodDays(days);

      return () => unsubscribe();
    }
  }, [selectedEmployee, filterType, selectedMonth, dateRange]);

  const handleDateRangeSelect = (selectedRange) => {
    if (!selectedRange) {
      setDateRange({ from: undefined, to: undefined });
      return;
    }

    const { from, to } = selectedRange;

    if (!from) {
      setDateRange({ from: undefined, to: undefined });
    } else if (!to) {
      setDateRange({ from: startOfDay(from), to: undefined });
    } else {
      const start = startOfDay(from);
      const end = endOfDay(to);
      setDateRange({ from: start, to: end });
      setIsCalendarOpen(false);
    }
  };

  const handleMonthSelect = (date) => {
    setSelectedMonth(date);
    setIsCalendarOpen(false);
  };

  const handleFilterTypeChange = (newFilterType) => {
    setIsSelectingFilterType(true);
    setFilterType(newFilterType);
    setIsCalendarOpen(false);
    if (newFilterType === 'custom') {
      setDateRange({ from: undefined, to: undefined });
    }
    setTimeout(() => setIsSelectingFilterType(false), 100);
  };

  const handleCalendarOpenChange = (open) => {
    if (!isSelectingFilterType) {
      setIsCalendarOpen(open);
      if (open && filterType === 'custom') {
        setDateRange({ from: undefined, to: undefined });
      }
    }
  };

  const handleEmployeeSelect = (value) => {
    setIsSelectingFilterType(true);
    setSelectedEmployee(employees.find(emp => emp.id === value));
    setIsCalendarOpen(false);
    setTimeout(() => setIsSelectingFilterType(false), 100);
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Payment Summary</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <div className="flex flex-col sm:flex-row items-start sm:items-center space-y-2 sm:space-y-0 sm:space-x-4">
            <div className="w-full sm:w-auto">
              <Label htmlFor="employee" className="mb-1 block">Select Employee:</Label>
              <Select value={selectedEmployee?.id || ''} onValueChange={handleEmployeeSelect}>
                <SelectTrigger id="employee" className="w-full sm:w-[240px] focus:ring-0 focus:ring-offset-0">
                  <SelectValue placeholder="Select employee" />
                </SelectTrigger>
                <SelectContent>
                  {employees.map((emp) => (
                    <SelectItem key={emp.id} value={emp.id}>{emp.name}</SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <div className="w-full sm:w-auto">
              <Label htmlFor="filterType" className="mb-1 block">Filter by:</Label>
              <Select value={filterType} onValueChange={handleFilterTypeChange}>
                <SelectTrigger id="filterType" className="w-full sm:w-[240px] focus:ring-0 focus:ring-offset-0">
                  <SelectValue placeholder="Select filter type" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="month">Month</SelectItem>
                  <SelectItem value="custom">Custom Date Range</SelectItem>
                </SelectContent>
              </Select>
            </div>

            {filterType === 'month' ? (
              <div className="w-full sm:w-auto">
                <Label htmlFor="month" className="mb-1 block">Select Month:</Label>
                <Popover open={isCalendarOpen} onOpenChange={handleCalendarOpenChange}>
                  <PopoverTrigger asChild>
                    <Button
                      id="month"
                      variant={"outline"}
                      className={cn(
                        "w-full sm:w-[240px] justify-start text-left font-normal",
                        !selectedMonth && "text-muted-foreground"
                      )}
                    >
                      <CalendarIcon className="mr-2 h-4 w-4" />
                      {selectedMonth ? format(selectedMonth, 'MMMM yyyy') : <span>Pick a month</span>}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <MonthCalendar
                      value={selectedMonth}
                      onChange={handleMonthSelect}
                    />
                  </PopoverContent>
                </Popover>
              </div>
            ) : (
              <div className="w-full sm:w-auto">
                <Label className="mb-1 block">Date Range:</Label>
                <Popover 
                  open={isCalendarOpen} 
                  onOpenChange={handleCalendarOpenChange}
                >
                  <PopoverTrigger asChild>
                    <Button
                      variant={"outline"}
                      className={cn(
                        "w-full sm:w-[240px] justify-start text-left font-normal",
                        !dateRange.from && "text-muted-foreground"
                      )}
                    >
                      <CalendarIcon className="mr-2 h-4 w-4" />
                      {dateRange.from ? (
                        dateRange.to ? (
                          <>
                            {format(dateRange.from, "LLL dd, y")} -{" "}
                            {format(dateRange.to, "LLL dd, y")}
                          </>
                        ) : (
                          format(dateRange.from, "LLL dd, y")
                        )
                      ) : (
                        <span>Pick a date range</span>
                      )}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      initialFocus
                      mode="range"
                      defaultMonth={dateRange.from}
                      selected={dateRange}
                      onSelect={handleDateRangeSelect}
                      numberOfMonths={isMobile ? 1 : 2}
                      disabled={(date) =>
                        date > new Date() || date < new Date("1900-01-01")
                      }
                    />
                  </PopoverContent>
                </Popover>
              </div>
            )}
          </div>

          <Badge variant="secondary">{periodDays} days</Badge>

          {selectedEmployee && (
            <div>
              <div className="mb-4">
                <h2 className="text-xl font-semibold">Payments for {selectedEmployee.name}</h2>
              </div>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Date</TableHead>
                    <TableHead>Amount</TableHead>
                    <TableHead>Type</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {payments.map((payment) => (
                    <TableRow key={payment.id}>
                      <TableCell>{format(new Date(payment.date), 'dd MMM yyyy')}</TableCell>
                      <TableCell>₹{payment.amount}</TableCell>
                      <TableCell>
                        <Badge variant={payment.type === 'advance' ? 'secondary' : 'green'}>
                          {payment.type === 'advance' ? 'Advance Payment' : 'Monthly Payment'}
                        </Badge>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default PaymentSummary;