import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { 
  format, 
  differenceInDays, 
  startOfMonth, 
  endOfMonth, 
  startOfDay, 
  endOfDay, 
  eachDayOfInterval, 
  addMonths, 
  subMonths, 
  addYears, 
  subYears, 
  isSameMonth, 
  startOfYear
} from 'date-fns';
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { Label } from "../components/ui/label";
import { Badge } from "../components/ui/badge";
import { Button } from "../components/ui/button";
import { Calendar } from "../components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "../components/ui/popover";
import { cn } from "../lib/utils";
import { CalendarIcon, ChevronLeft, ChevronRight } from "lucide-react";
import { ScrollArea, ScrollBar } from "../components/ui/scroll-area";
import { useWindowSize } from "../hooks/use-window-size";

const MonthCalendar = ({ value, onChange }) => {
  const [year, setYear] = useState(value.getFullYear());

  const months = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const handleYearChange = (increment) => {
    setYear(prevYear => prevYear + increment);
  };

  const handleMonthSelect = (month) => {
    onChange(new Date(year, month, 1));
  };

  return (
    <div className="p-3">
      <div className="flex justify-between items-center mb-4">
        <Button
          variant="outline"
          size="icon"
          onClick={() => handleYearChange(-1)}
        >
          <ChevronLeft className="h-4 w-4" />
        </Button>
        <span className="text-lg font-semibold">{year}</span>
        <Button
          variant="outline"
          size="icon"
          onClick={() => handleYearChange(1)}
        >
          <ChevronRight className="h-4 w-4" />
        </Button>
      </div>
      <div className="grid grid-cols-3 gap-2">
        {months.map((month, index) => {
          const date = new Date(year, index, 1);
          const isSelected = isSameMonth(date, value);
          return (
            <Button
              key={month}
              onClick={() => handleMonthSelect(index)}
              variant={isSelected ? "default" : "outline"}
              className={cn(
                "h-10",
                isSelected && "bg-primary text-primary-foreground hover:bg-primary/90",
                !isSelected && "hover:bg-accent hover:text-accent-foreground"
              )}
            >
              {month}
            </Button>
          );
        })}
      </div>
    </div>
  );
};

const AttendanceDashboard = () => {
  const [employees, setEmployees] = useState([]);
  const [attendance, setAttendance] = useState({});
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [filterType, setFilterType] = useState('month');
  const [dateRange, setDateRange] = useState({ from: undefined, to: undefined });
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isSelectingFilterType, setIsSelectingFilterType] = useState(false);
  const { width } = useWindowSize();
  const isMobile = width < 640;

  useEffect(() => {
    const fetchEmployees = async () => {
      const employeesCollection = collection(db, 'employees');
      const employeesSnapshot = await getDocs(employeesCollection);
      const employeesList = employeesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      const sortedEmployees = employeesList.sort((a, b) => a.name.localeCompare(b.name));
      setEmployees(sortedEmployees);
    };

    fetchEmployees();
  }, []);

  const handleDateRangeSelect = (selectedRange) => {
    if (!selectedRange) {
      setDateRange({ from: undefined, to: undefined });
      return;
    }

    const { from, to } = selectedRange;

    if (!from) {
      setDateRange({ from: undefined, to: undefined });
    } else if (!to) {
      setDateRange({ from: startOfDay(from), to: undefined });
    } else {
      const start = startOfDay(from);
      const end = endOfDay(to);
      setDateRange({ from: start, to: end });
      setIsCalendarOpen(false); // Close calendar after selecting both dates
    }
  };

  useEffect(() => {
    const fetchAttendance = async () => {
      let start, end;
      if (filterType === 'month') {
        start = startOfMonth(selectedMonth);
        end = endOfMonth(selectedMonth);
      } else if (dateRange.from && dateRange.to) {
        start = dateRange.from;
        end = dateRange.to;
      } else {
        // If dateRange is not properly set, use the current month
        start = startOfMonth(new Date());
        end = endOfMonth(new Date());
      }

      const formattedStart = format(start, 'yyyy-MM-dd');
      const formattedEnd = format(end, 'yyyy-MM-dd');

      const attendanceCollection = collection(db, 'attendance');
      const q = query(
        attendanceCollection,
        where('date', '>=', formattedStart),
        where('date', '<=', formattedEnd)
      );
      const attendanceSnapshot = await getDocs(q);
      const attendanceData = {};
      attendanceSnapshot.docs.forEach(doc => {
        const data = doc.data();
        if (!attendanceData[data.employee_id]) {
          attendanceData[data.employee_id] = {};
        }
        attendanceData[data.employee_id][data.date] = data.status;
      });
      setAttendance(attendanceData);
    };

    if (filterType === 'month' || (filterType === 'custom' && dateRange.from && dateRange.to)) {
      fetchAttendance();
    }
  }, [filterType, selectedMonth, dateRange]);

  const getDates = () => {
    if (filterType === 'month') {
      return eachDayOfInterval({ start: startOfMonth(selectedMonth), end: endOfMonth(selectedMonth) });
    } else if (dateRange.from && dateRange.to) {
      return eachDayOfInterval({ start: dateRange.from, end: dateRange.to });
    }
    return [];
  };

  const dates = getDates();

  const handleMonthSelect = (date) => {
    setSelectedMonth(date);
    setIsCalendarOpen(false); // Close calendar after selecting a month
  };

  const handleFilterTypeChange = (newFilterType) => {
    setIsSelectingFilterType(true);
    setFilterType(newFilterType);
    setIsCalendarOpen(false); // Close the calendar when switching filter types
    if (newFilterType === 'custom') {
      setDateRange({ from: undefined, to: undefined }); // Reset dateRange when switching to custom
    }
    setTimeout(() => setIsSelectingFilterType(false), 100);
  };

  const handleCalendarOpenChange = (open) => {
    if (!isSelectingFilterType) {
      setIsCalendarOpen(open);
    }
  };

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle>Attendance Dashboard</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <div className="flex flex-col sm:flex-row items-start sm:items-center space-y-2 sm:space-y-0 sm:space-x-4">
            <div className="w-full sm:w-auto">
              <Label htmlFor="filterType" className="mb-1 block">Filter by:</Label>
              <Select value={filterType} onValueChange={handleFilterTypeChange}>
                <SelectTrigger id="filterType" className="w-full sm:w-[240px] focus:ring-0 focus:ring-offset-0">
                  <SelectValue placeholder="Select filter type" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="month">Month</SelectItem>
                  <SelectItem value="custom">Custom Date Range</SelectItem>
                </SelectContent>
              </Select>
            </div>

            {filterType === 'month' ? (
              <div className="w-full sm:w-auto">
                <Label htmlFor="month" className="mb-1 block">Select Month:</Label>
                <Popover open={isCalendarOpen} onOpenChange={handleCalendarOpenChange}>
                  <PopoverTrigger asChild>
                    <Button
                      id="month"
                      variant={"outline"}
                      className={cn(
                        "w-full sm:w-[240px] justify-start text-left font-normal",
                        !selectedMonth && "text-muted-foreground"
                      )}
                    >
                      <CalendarIcon className="mr-2 h-4 w-4" />
                      {selectedMonth ? format(selectedMonth, 'MMMM yyyy') : <span>Pick a month</span>}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <MonthCalendar
                      value={selectedMonth}
                      onChange={handleMonthSelect}
                    />
                  </PopoverContent>
                </Popover>
              </div>
            ) : (
              <div className="w-full sm:w-auto">
                <Label className="mb-1 block">Date Range:</Label>
                <Popover 
                  open={isCalendarOpen} 
                  onOpenChange={(open) => {
                    if (!isSelectingFilterType) {
                      setIsCalendarOpen(open);
                      if (open) {
                        setDateRange({ from: undefined, to: undefined }); // Reset dateRange when opening the calendar
                      }
                    }
                  }}
                >
                  <PopoverTrigger asChild>
                    <Button
                      variant={"outline"}
                      className={cn(
                        "w-full sm:w-[240px] justify-start text-left font-normal",
                        !dateRange.from && "text-muted-foreground"
                      )}
                    >
                      <CalendarIcon className="mr-2 h-4 w-4" />
                      {dateRange.from ? (
                        dateRange.to ? (
                          <>
                            {format(dateRange.from, "LLL dd, y")} -{" "}
                            {format(dateRange.to, "LLL dd, y")}
                          </>
                        ) : (
                          format(dateRange.from, "LLL dd, y")
                        )
                      ) : (
                        <span>Pick a date range</span>
                      )}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      initialFocus
                      mode="range"
                      defaultMonth={dateRange.from}
                      selected={dateRange}
                      onSelect={handleDateRangeSelect}
                      numberOfMonths={isMobile ? 1 : 2}
                      disabled={(date) =>
                        date > new Date() || date < new Date("1900-01-01")
                      }
                    />
                  </PopoverContent>
                </Popover>
              </div>
            )}
          </div>

          <Badge variant="secondary">{dates.length} days</Badge>

          {dates.length > 0 ? (
            <ScrollArea className="w-full whitespace-nowrap rounded-md border">
              <div className="relative w-full">
                <table className="w-full caption-bottom text-sm">
                  <thead className="[&_tr]:border-b">
                    <tr className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                      <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0 sticky left-0 bg-background">
                        Employee Name
                      </th>
                      {dates.map((date, i) => (
                        <th key={i} className="h-12 px-2 text-center align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0">
                          {format(date, 'd MMM')}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="[&_tr:last-child]:border-0">
                    {employees.map((emp) => (
                      <tr key={emp.id} className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                        <td className="p-4 align-middle [&:has([role=checkbox])]:pr-0 font-medium sticky left-0 bg-background">
                          {emp.name}
                        </td>
                        {dates.map((date, i) => {
                          const formattedDate = format(date, 'yyyy-MM-dd');
                          const status = attendance[emp.id]?.[formattedDate];
                          return (
                            <td key={i} className="p-2 align-middle [&:has([role=checkbox])]:pr-0 text-center">
                              <div className={`w-4 h-4 rounded-full mx-auto ${
                                status === 'present' ? 'bg-green-500' :
                                status === 'absent' ? 'bg-red-500' :
                                'bg-muted-foreground/20'
                              }`}></div>
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <ScrollBar orientation="horizontal" />
            </ScrollArea>
          ) : (
            <p className="text-center text-gray-500">No data available for the selected period.</p>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default AttendanceDashboard;