import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, doc, setDoc } from 'firebase/firestore';
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { Label } from "../components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "../components/ui/popover";
import { Calendar } from "../components/ui/calendar";
import { CalendarIcon } from "lucide-react";
import { format } from "date-fns";
import { cn } from "../lib/utils";
import { Alert, AlertDescription } from "../components/ui/alert";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../components/ui/alert-dialog";
import { useWindowSize } from "../hooks/use-window-size";

const UpdateAttendance = () => {
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [date, setDate] = useState();
  const [status, setStatus] = useState('present');
  const [alertInfo, setAlertInfo] = useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isSelectingEmployee, setIsSelectingEmployee] = useState(false);
  const { width } = useWindowSize();
  const isMobile = width < 640;

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const employeesCollection = collection(db, 'employees');
        const employeesSnapshot = await getDocs(employeesCollection);
        const employeesList = employeesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setEmployees(employeesList);
      } catch (error) {
        console.error('Error fetching employees:', error);
      }
    };

    fetchEmployees();
  }, []);

  useEffect(() => {
    let timer;
    if (alertInfo) {
      timer = setTimeout(() => {
        setAlertInfo(null);
      }, 3000);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [alertInfo]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!selectedEmployee || !date) return;
    setShowConfirmDialog(true);
  };

  const confirmAttendanceUpdate = async () => {
    try {
      const formattedDate = format(date, 'yyyy-MM-dd');
      const attendanceRef = doc(db, 'attendance', `${selectedEmployee}_${formattedDate}`);
      await setDoc(attendanceRef, {
        employee_id: selectedEmployee,
        date: formattedDate,
        status
      }, { merge: true });
      setAlertInfo({ type: 'success', message: 'Attendance updated successfully' });
      setSelectedEmployee('');
      setDate(undefined);
      setStatus('present');
    } catch (error) {
      console.error('Error updating attendance:', error);
      setAlertInfo({ type: 'error', message: 'Error updating attendance. Please try again.' });
    }
    setShowConfirmDialog(false);
  };

  const selectedEmployeeName = employees.find(emp => emp.id === selectedEmployee)?.name || '';

  const handleDateSelect = (selectedDate) => {
    setDate(selectedDate);
    setIsCalendarOpen(false);
  };

  const handleEmployeeSelect = (value) => {
    setIsSelectingEmployee(true);
    setSelectedEmployee(value);
    setTimeout(() => setIsSelectingEmployee(false), 100);
  };

  const handleCalendarOpenChange = (open) => {
    if (!isSelectingEmployee) {
      setIsCalendarOpen(open);
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Update Attendance</CardTitle>
      </CardHeader>
      <CardContent>
        {alertInfo && (
          <Alert variant={alertInfo.type === 'success' ? 'default' : 'destructive'} className="mb-4">
            <AlertDescription>
              <strong>{alertInfo.type === 'success' ? 'Success: ' : 'Error: '}</strong>
              {alertInfo.message}
            </AlertDescription>
          </Alert>
        )}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="employee">Select Employee</Label>
            <Select value={selectedEmployee} onValueChange={handleEmployeeSelect}>
              <SelectTrigger id="employee">
                <SelectValue placeholder="Select an employee" />
              </SelectTrigger>
              <SelectContent>
                {employees.map((emp) => (
                  <SelectItem key={emp.id} value={emp.id}>{emp.name}</SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="space-y-2">
            <Label htmlFor="date">Date</Label>
            <Popover open={isCalendarOpen} onOpenChange={handleCalendarOpenChange}>
              <PopoverTrigger asChild>
                <Button
                  variant={"outline"}
                  className={cn(
                    "w-full justify-start text-left font-normal",
                    !date && "text-muted-foreground"
                  )}
                >
                  <CalendarIcon className="mr-2 h-4 w-4" />
                  {date ? format(date, "PPP") : <span>Pick a date</span>}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0" align="start">
                <Calendar
                  mode="single"
                  selected={date}
                  onSelect={handleDateSelect}
                  initialFocus
                />
              </PopoverContent>
            </Popover>
          </div>
          <div className="space-y-2">
            <Label htmlFor="status">Status</Label>
            <Select value={status} onValueChange={setStatus}>
              <SelectTrigger id="status">
                <SelectValue placeholder="Select status" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="present">Present</SelectItem>
                <SelectItem value="absent">Absent</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <Button type="submit" className="w-full">Update Attendance</Button>
        </form>

        <AlertDialog open={showConfirmDialog} onOpenChange={setShowConfirmDialog}>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Confirm Attendance Update</AlertDialogTitle>
            </AlertDialogHeader>
            <AlertDialogDescription>
              Are you sure you want to update the attendance for:
              <ul className="mt-2">
                <li><strong>Employee:</strong> {selectedEmployeeName}</li>
                <li><strong>Date:</strong> {date ? format(date, "PPP") : 'N/A'}</li>
                <li><strong>Status:</strong> {status.charAt(0).toUpperCase() + status.slice(1)}</li>
              </ul>
            </AlertDialogDescription>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction onClick={confirmAttendanceUpdate}>Confirm</AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </CardContent>
    </Card>
  );
};

export default UpdateAttendance;