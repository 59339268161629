import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, Outlet } from 'react-router-dom';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from '../firebase';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { Button } from './ui/button';
import { ScrollArea } from './ui/scroll-area';
import { MoonIcon, SunIcon, Menu } from 'lucide-react';
import { useTheme } from './theme-provider';
import useWindowSize from '../hooks/useWindowSize';
import { Loader2 } from 'lucide-react';

const db = getFirestore();

const Layout = () => {
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { theme, setTheme } = useTheme();
  const { width } = useWindowSize();

  const isMobile = width < 768;

  useEffect(() => {
    console.log('Layout useEffect running');

    let isMounted = true; // To prevent state updates if the component is unmounted

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (!isMounted) return;
      console.log('Auth state changed, user:', user?.email);
      if (!user) {
        setLoading(false);
        navigate('/login', { replace: true });
      } else {
        const adminStatus = await checkAdminStatus(user);
        console.log('Admin status:', adminStatus);
        setIsAdmin(adminStatus);
        setLoading(false);
      }
    });

    // Cleanup function
    return () => {
      isMounted = false;
      unsubscribe();
    };
  }, [navigate]);

  // Handle viewport height units on mobile devices
  useEffect(() => {
    const setViewportHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    setViewportHeight();
    window.addEventListener('resize', setViewportHeight);

    return () => {
      window.removeEventListener('resize', setViewportHeight);
    };
  }, []);

  const navItems = [
    { path: '/', label: 'Home' },
    { path: '/attendance-dashboard', label: 'Attendance Dashboard' },
    { path: '/attendance-summary', label: 'Attendance Summary' },
    { path: '/employee-details', label: 'Employee Details' },
    { path: '/payment-summary', label: 'Payment Summary' },
  ];

  const adminNavItems = [
    { path: '/add-employee', label: 'Add Employee' },
    { path: '/remove-employee', label: 'Remove Employee' },
    { path: '/update-attendance', label: 'Update Attendance' },
  ];

  const combinedNavItems = isAdmin ? [...navItems, ...adminNavItems] : navItems;

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const loadingMessages = [
    'Preparing your workspace...',
    'Getting everything ready...',
    'Fetching your data...',
    'Just a moment, please...',
    'Initializing your session...',
  ];

  // Initialize the loading message once
  const [loadingMessage] = useState(
    loadingMessages[Math.floor(Math.random() * loadingMessages.length)]
  );

  return (
    <div
      className="flex flex-col bg-background text-foreground"
      style={{ minHeight: 'calc(var(--vh, 1vh) * 100)' }}
    >
      {loading ? (
        <div className="flex items-center justify-center flex-grow">
          <div className="text-center">
            <Loader2 className="h-12 w-12 animate-spin text-primary mx-auto" />
            <p className="mt-4 text-lg font-medium text-foreground">
              {loadingMessage}
            </p>
          </div>
        </div>
      ) : (
        <>
          {isMobile && (
            <header className="flex justify-between items-center p-4 border-b">
              <h1 className="text-2xl font-bold">Employee App</h1>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => setMenuOpen(!menuOpen)}
              >
                <Menu />
              </Button>
            </header>
          )}
          <div className="flex flex-1 overflow-hidden">
            {!isMobile ? (
              <aside className="w-64 border-r flex flex-col ipad-sidebar">
                <div className="p-4">
                  <h1 className="text-2xl font-bold">Employee App</h1>
                </div>
                <ScrollArea className="flex-1">
                  <nav className="space-y-1 p-2">
                    {combinedNavItems.map((item) => (
                      <Link
                        key={item.path}
                        to={item.path}
                        className={`flex items-center space-x-2 rounded-lg px-3 py-2 text-sm font-medium transition-colors ${
                          location.pathname === item.path
                            ? 'bg-accent text-accent-foreground'
                            : 'hover:bg-accent hover:text-accent-foreground'
                        }`}
                        onClick={() => isMobile && setMenuOpen(false)}
                      >
                        {item.label}
                      </Link>
                    ))}
                  </nav>
                </ScrollArea>
                <div className="p-4">
                  <div className="flex space-x-2 mb-2">
                    <Button
                      onClick={handleLogout}
                      className="flex-1"
                      variant="destructive"
                    >
                      Logout
                    </Button>
                    <Button
                      variant="outline"
                      size="icon"
                      onClick={() =>
                        setTheme(theme === 'dark' ? 'light' : 'dark')
                      }
                    >
                      {theme === 'dark' ? <SunIcon /> : <MoonIcon />}
                    </Button>
                  </div>
                </div>
              </aside>
            ) : menuOpen && (
              <div className="absolute top-16 left-0 right-0 bg-background border-b z-10">
                <nav className="p-2">
                  {combinedNavItems.map((item) => (
                    <Link
                      key={item.path}
                      to={item.path}
                      className={`flex items-center space-x-2 rounded-lg px-3 py-2 text-sm font-medium transition-colors ${
                        location.pathname === item.path
                          ? 'bg-accent text-accent-foreground'
                          : 'hover:bg-accent hover:text-accent-foreground'
                      }`}
                      onClick={() => isMobile && setMenuOpen(false)}
                    >
                      {item.label}
                    </Link>
                  ))}
                  <div className="flex items-center mt-2">
                    <Button
                      onClick={handleLogout}
                      className="flex-1 mr-2"
                      variant="destructive"
                    >
                      Logout
                    </Button>
                    <Button
                      variant="outline"
                      size="icon"
                      onClick={() =>
                        setTheme(theme === 'dark' ? 'light' : 'dark')
                      }
                    >
                      {theme === 'dark' ? <SunIcon /> : <MoonIcon />}
                    </Button>
                  </div>
                </nav>
              </div>
            )}
            <main className="flex-1 p-4 md:p-8 overflow-y-auto">
              <Outlet context={{ isAdmin }} />
            </main>
          </div>
        </>
      )}
    </div>
  );
};

async function checkAdminStatus(user) {
  console.log('Checking admin status for user:', user.email);
  try {
    const userDoc = await getDoc(doc(db, 'users', user.uid));
    if (userDoc.exists()) {
      const userData = userDoc.data();
      console.log('User data:', userData);
      return userData.isAdmin === true;
    }
    return false;
  } catch (error) {
    console.error('Error checking admin status:', error);
    return false;
  }
}

export default Layout;
