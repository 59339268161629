import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../components/ui/table";
import { Badge } from "../components/ui/badge"; // Add this import

const Home = () => {
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const employeesCollection = collection(db, 'employees');
        const employeesSnapshot = await getDocs(employeesCollection);
        const employeesList = employeesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        const sortedEmployees = employeesList.sort((a, b) => a.name.localeCompare(b.name));
        setEmployees(sortedEmployees);
      } catch (error) {
        console.error('Error fetching employees:', error);
      }
    };

    fetchEmployees();
  }, []);

  const getBadgeColor = (position) => {
    switch (position) {
      case 'Manager': return 'bg-blue-500 hover:bg-blue-600';
      case 'Receptionist': return 'bg-green-500 hover:bg-green-600';
      case 'Housekeeping': return 'bg-purple-500 hover:bg-purple-600';
      case 'Day Shift': return 'bg-yellow-500 hover:bg-yellow-600';
      case 'Night Shift': return 'bg-red-500 hover:bg-red-600';
      default: return 'bg-gray-500 hover:bg-gray-600';
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Employee Directory</CardTitle>
      </CardHeader>
      <CardContent>
        {employees.length > 0 ? (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Employee</TableHead>
                <TableHead>Payment Date</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {employees.map((emp) => (
                <TableRow key={emp.id}>
                  <TableCell>
                    <div className="flex flex-col">
                      <span className="font-medium">{emp.name}</span>
                      <Badge 
                        className={`mt-1 w-fit text-white ${getBadgeColor(emp.position || 'N/A')}`}
                      >
                        {emp.position || 'N/A'}
                      </Badge>
                    </div>
                  </TableCell>
                  <TableCell>{emp.payment_date ? new Date(emp.payment_date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Not specified'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <p className="text-center text-muted-foreground">No employees found.</p>
        )}
      </CardContent>
    </Card>
  );
};

export default Home;